function map_post_data(project, link_prefix) {
    if(project.acf.choice_of_project == 'Landing Page') {
        return {
            type: 'landing',
            title: project.title.rendered,
            image: project['_embedded']['wp:featuredmedia'] ? project['_embedded']['wp:featuredmedia'][0]['media_details']['sizes']['medium_large']['source_url'] : null,
            link: project.acf.landing_page,
        }
    } else {
        return {
            type: 'project',
            title: project.title.rendered,
            image: project['_embedded']['wp:featuredmedia'] ? project['_embedded']['wp:featuredmedia'][0]['media_details']['sizes']['medium_large']['source_url'] : null,
            link: '/' + link_prefix + '/' + project.slug,
        }
    }
}

var project_acf_mapper = {
    methods: {
        map_projects_from_acf: function (items) {
            let projects = [];
            if(!items) return projects;

            items.map(p => {
                let rp = this.$store.state.content.project.find(x => x.id === p.ID)
                if(rp !== undefined) {
                    projects.push(map_post_data(rp, 'project'))
                }
            })
            return projects;
        }
    }
}

var project_post_mapper = {
    methods: {
        map_projects_from_post: function(items) {
            if(!items) return;
            let projects = [];
            items.map(p => {
                projects.push(map_post_data(p, 'project'))
            })
            return projects
        }
    }
}

var training_mapper = {
    methods: {
        map_training_to_block_listing(items) {
            let trainings = [];
            if(!items) return false;

            items.map(p => {
                let rp = this.$store.state.content.training.find(x => x.id === p.ID)
                if(rp === undefined) {
                    this.$store.dispatch('getAllTrainings')
                    return;
                }
                trainings.push(map_post_data(rp, 'training'))
            })
            return trainings;
        }
    }
}

var date_formater = {
    methods: {
        date_from_wp(date) {
            let d = new Date(date)
            return d.getDate().toString().padStart(2, "0") + '.' + (d.getMonth() + 1).toString().padStart(2, "0") + '.' + d.getFullYear()
        }
    }
}

var url_formater = {
    methods: {
        url_from_wp(url) {
            return url
        }
    }
}

var search_filter = {
    data() {
        return {
            filters: {
                search: '',
            },
        }
    },
    methods: {
        filterUsingSearch(n, acf = false) {
            if(!this.filters.search) return true;
            if(acf) return (n.file.filename.toLowerCase().includes(this.filters.search.toLowerCase()))
            return (n.title.rendered.toLowerCase().includes(this.filters.search.toLowerCase()))
        }
    }
}

var tag_filter = {
    data() {
        return {
            filters: {
                tags: [],
            },
        }
    },
    mounted() {
        this.$store.dispatch('getTags')
    },
    computed: {
        tags() {
            return this.$store.state.content.tags
        }
    },
    methods: {
        addTagFilter(t) {
            if(this.filters.tags.find(i => i === t)) return;
            this.$set(this.filters, 'tags', [...this.filters.tags, t])
        },
        removeTagFilter(t) {
            this.$set(this.filters, 'tags', this.filters.tags.filter(x => x !== t))
        },
        filterUsingTag(n, acf = false) {
            if(acf) return this.filterUsingTagAcf(n)
            let ftags = this.filters.tags
            if(ftags.length === 0) return true;
            if(!n._embedded || !n._embedded['wp:term']) return false;
            let ntags = n._embedded['wp:term'][0]
            if(ntags.some(t => ftags.find(x => t.id === x.id))) return true;
            return false;
        },
        filterUsingTagAcf(n) {
            let ftags = this.filters.tags
            if(ftags.length === 0) return true;
            if(!n.tags) return false;
            let ntags = n.tags
            if(ntags.some(t => ftags.find(x => t.term_id === x.id))) return true;
            return false;
        }
    }
}

export {
    project_acf_mapper,
    project_post_mapper,
    training_mapper,
    date_formater,
    url_formater,
    tag_filter,
    search_filter,
    map_post_data
}
